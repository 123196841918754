import tinycolor from 'tinycolor2';

const lightenRate = 7.5;
const darkenRate = 15;

const palette = {
  primary: {
    main: '#472087',
    light: tinycolor('#472087').lighten(lightenRate).toHexString(),
    dark: tinycolor('#472087').darken(darkenRate).toHexString(),
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#FF8200',
    light: tinycolor('#FF8200').lighten(lightenRate).toHexString(),
    dark: tinycolor('#FF8200').darken(darkenRate).toHexString(),
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#EF6565',
    light: tinycolor('#EF6565').lighten(lightenRate).toHexString(),
    dark: tinycolor('#EF6565').darken(darkenRate).toHexString(),
    contrastText: '#ffa7a1',
  },
  warning: {
    main: '#fff0c9',
    light: tinycolor('#fff0c9').lighten(lightenRate).toHexString(),
    dark: tinycolor('#fff0c9').darken(darkenRate).toHexString(),
    contrastText: '#ffe296',
  },
  info: {
    main: '#cfeeff',
    light: tinycolor('#cfeeff').lighten(lightenRate).toHexString(),
    dark: tinycolor('#cfeeff').darken(darkenRate).toHexString(),
    contrastText: '#9cdcff',
  },
  success: {
    main: '#c9ffd5',
    light: tinycolor('#c9ffd5').lighten(lightenRate).toHexString(),
    dark: tinycolor('#c9ffd5').darken(darkenRate).toHexString(),
    contrastText: '#96ffad',
  },
  text: {
    primary: '#3E3E3E',
    secondary: '#6E6E6E',
    hint: '#B9B9B9',
    light: '#CDCDCD',
  },
  background: {
    default: '#F7F3F0', // Warm White in the Style Guide
    workbench: '#E6E4E9',
    workbenchDark: '#f0eff2',
    paper: '#ffffff',
    light: '#fbfaf9',
    dark: '#ECE9E6',
    hoverItem: '#F7E8D8',
    activeItem: '#F7E8D8',
  },
  support: {
    yellow: {
      darkest: '#322815',
      darker: '#65522A',
      dark: '#997D40',
      main: '#FFD16C',
      light: '#FFE3A6',
      lighter: '#FFECC4',
      lightest: '#FFF5E2',
    },
    orange: {
      darkest: '#311E0D',
      darker: '#633D1A',
      dark: '#975D28',
      main: '#FC9B44',
      light: '#FDC38E',
      lighter: '#FDD7B4',
      lightest: '#FEEBDA',
    },
    red: {
      darkest: '#2E1313',
      darker: '#5E2828',
      dark: '#8F3C3C',
      main: '#EF6565',
      light: '#F5A2A2',
      lighter: '#F8C2C2',
      lightest: '#FBE0E0',
    },
    pink: {
      darkest: '#301A26',
      darker: '#61354D',
      dark: '#945075',
      main: '#F786C4',
      light: '#FAB6DB',
      lighter: '#FBCFE7',
      lightest: '#FDE7F3',
    },
    purple: {
      darkest: '#1A1626',
      darker: '#352C4E',
      dark: '#504376',
      main: '#8671C6',
      light: '#B6A9DC',
      lighter: '#CFC6E8',
      lightest: '#E7E3F3',
    },
    darkBlue: {
      darkest: '#171D2F',
      darker: '#2E3B60',
      dark: '#465A92',
      main: '#7697F4',
      light: '#ACC0F8',
      lighter: '#C8D5FA',
      lightest: '#E4EAFC',
    },
    lightBlue: {
      darkest: '#1B2832',
      darker: '#365265',
      dark: '#527C99',
      main: '#8AD0FF',
      light: '#B8E2FF',
      lighter: '#D0ECFF',
      lightest: '#E8F5FF',
    },
    green: {
      darkest: '#142622',
      darker: '#284D46',
      dark: '#3D756A',
      main: '#66C4B2',
      light: '#A3DBD0',
      lighter: '#C2E7E0',
      lightest: '#E1F3EF',
    },
  },
  extended: {
    extended1: {
      darkest: '#2E1313',
      darker: '#5E2828',
      dark: '#8F3C3C',
      main: '#EF6565',
      light: '#F5A2A2',
      lighter: '#F8C2C2',
      lightest: '#FBE0E0',
    },
    extended2: {
      darkest: '#2F1914',
      darker: '#603228',
      dark: '#914C3D',
      main: '#F38067',
      light: '#F7B2A3',
      lighter: '#FACCC2',
      lightest: '#FCE6E1',
    },
    extended3: {
      darkest: '#301E14',
      darker: '#613D29',
      dark: '#945D3F',
      main: '#F79B69',
      light: '#FAC3A5',
      lighter: '#FBD7C3',
      lightest: '#FDEBE1',
    },
    extended4: {
      darkest: '#312314',
      darker: '#634829',
      dark: '#966D3F',
      main: '#FBB66A',
      light: '#FCD3A5',
      lighter: '#FDE2C3',
      lightest: '#FEF0E1',
    },
    extended5: {
      darkest: '#322815',
      darker: '#65522A',
      dark: '#997D40',
      main: '#FFD16C',
      light: '#FFE3A6',
      lighter: '#FFECC4',
      lightest: '#FFF5E2',
    },
    extended6: {
      darkest: '#2B2817',
      darker: '#585130',
      dark: '#867B49',
      main: '#E0CE7A',
      light: '#ECE1AF',
      lighter: '#F2EBCA',
      lightest: '#F8F5E4',
    },
    extended7: {
      darkest: '#26281A',
      darker: '#4C5035',
      dark: '#747A51',
      main: '#C2CC88',
      light: '#DAE0B7',
      lighter: '#E6EACF',
      lightest: '#F3F5E7',
    },
    extended8: {
      darkest: '#1F271D',
      darker: '#404F3B',
      dark: '#61785A',
      main: '#A3C996',
      light: '#C7DEC0',
      lighter: '#DAE9D5',
      lightest: '#ECF4EA',
    },
    extended9: {
      darkest: '#1A2720',
      darker: '#344E40',
      dark: '#4F7762',
      main: '#85C7A4',
      light: '#B5DDC8',
      lighter: '#CEE8DA',
      lightest: '#E7F4ED',
    },
    extended10: {
      darkest: '#142622',
      darker: '#284D46',
      dark: '#3D756A',
      main: '#66C4B2',
      light: '#A3DBD0',
      lighter: '#C2E7E0',
      lightest: '#E1F3EF',
    },
  },
};

export default palette;
