import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {staffSlice} from "../app/modules/UserManagement/_redux/staff/staffSlice";
import {practiceSlice} from "../app/modules/UserManagement/_redux/practice/practiceSlice";
import {householdSlice} from "../app/modules/UserManagement/_redux/household/householdSlice";
import {billableSlice} from "../app/modules/UserManagement/_redux/billable/billableSlice";
import {growthSlice} from "../app/modules/UserManagement/_redux/growth/growthSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  staff: staffSlice.reducer,
  practice: practiceSlice.reducer,
  household: householdSlice.reducer,
  billable: billableSlice.reducer,
  growth: growthSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
