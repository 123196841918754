

// The lineHeights below were converted to a ratio = originalLineHeight / fontSize
export default {
  themeName: 'Lumiant Default Theme',
  typography: {

  },
  breakpoints: {
    values: {
      xs: 0, // Mobile - using 375 as the standard (iPhone 6 onwards)
      sm: 600,
      smMaxContentArea: 550,
      smMaxContentPlusArea: 580,
      md: 768, // iPad
      mdMaxContentArea: 690,
      mdMaxContentPlusArea: 730,
      lg: 1024, // iPad Pro
      lgMaxContentArea: 870,
      lgMaxContentPlusArea: 920,
      xl: 1440, // Desktop
      xlMaxContentArea: 1140,
      xlMaxContentPlusArea: 1220,
      threeGraphs: 1270,
      viewport: 1440,
      maxContent: 1920,
    },
  },
};
