const cssOverrides = {
  MuiButton: {
    root: {
      textTransform: null,
    },
    containedPrimary: {
      color: 'white',
    },
    containedSecondary: {
      color: 'red',
    },
  },
  MuiTypography: {
    root: {
      wordBreak: 'break-word',
    },
  },
  MuiList: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  MuiInput: {
    root: {
      '&$focused': {
        borderColor: 'none',
      },
    },
    multiline: {
      border: '1px solid #c4c4c4',
      borderRadius: '5px',
      padding: '20px',
      '&:before': {
        content: 'unset',
      },
    },
  },
};

export default cssOverrides;
