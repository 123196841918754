import {createSlice} from "@reduxjs/toolkit";

const initialStaffState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  staffForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const staffSlice = createSlice({
  name: "staff",
  initialState: initialStaffState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findStaff
    staffFetched: (state, action) => {
      const { totalCount, staff } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = staff;
      state.totalCount = totalCount;
    },
    // createStaff
    staffCreated: (state, action) => {
      state.ewactionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.staff);
    },
    // updateCustomer
    staffUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.staff.id) {
          return action.payload.staff;
        }
        return entity;
      });
    },
    // deleteStaff
    staffDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // staffUpdateState
    staffStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
