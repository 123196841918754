const propOverrides = {
  MuiButton: {
    color: 'primary',
    variant: 'contained',
    size: 'large',
  },
  MuiRadio: {
    color: 'primary',
  },
  MuiTextField: {
    inputProps: {
      maxLength: 5000,
    },
    InputLabelProps: {
      shrink: true,
    },
  },
};

export default propOverrides;
