/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:f2c19db1-ecdd-4b91-8b70-ae7ca226fae2",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_mYLcD9jwm",
    "aws_user_pools_web_client_id": "761nqshcvl21bf3tg0vfm7u8n8",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "8",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "www.lumiant.com.au-qa",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://d1s4weo0qev2v8.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://hs5roxfhtrfkfox6nej6d36nvm.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "lumiant-data235323-qa",
    "aws_user_files_s3_bucket_region": "ap-southeast-2",
    "predictions": {
        "convert": {
            "transcription": {
                "region": "ap-southeast-2",
                "proxy": false,
                "defaults": {
                    "language": "en-US"
                }
            }
        }
    }
};


export default awsmobile;
