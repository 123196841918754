import React, {useEffect, useState} from "react";
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {API} from "@aws-amplify/api";
import {graphqlOperation} from "@aws-amplify/api-graphql";
import { generatePracticeTrendData, generateHouseholdTrendData, generateAdviserTrendData} from "../graphql/queries";

export function DashboardPage() {

    // todo move all this into redux store
    const [practiceTrendData, setPracticeTrendData] = useState();
    const [adviserTrendData, setAdviserTrendData] = useState();
    const [householdTrendData, setHouseholdTrendData] = useState();

    const convertTime = (timestamp ) =>{
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var d = new Date(timestamp);
        const year = d.getFullYear()
        const twoDigitYear = year.toString().substr(-2);
        return `${d.getDate()} ${months[d.getMonth()]}, ${twoDigitYear}`
    }

    useEffect(() => {
        practiceTrend();
        adviserTrend();
        householdTrend();
    },[]);


   const practiceTrend = async  () => {
    let trendReturn;
       trendReturn = await API.graphql(
        graphqlOperation(generatePracticeTrendData, {generatePracticeTrendDataInput:{}})
    );
       trendReturn = JSON.parse(trendReturn.data.generatePracticeTrendData)
       const data = trendReturn.map(a => a.num_practices);
       const dates = trendReturn.map(a => convertTime(a.Date));
       setPracticeTrendData({data,dates});
  }
  const adviserTrend = async  () => {
    let trendReturn;
      trendReturn = await API.graphql(
        graphqlOperation(generateAdviserTrendData, {generateAdviserTrendDataInput:{}})
    );
      trendReturn = JSON.parse(trendReturn.data.generateAdviserTrendData)
    const data = trendReturn.map(a => a.num_advisers);
    const dates = trendReturn.map(a => convertTime(a.Date));
      setAdviserTrendData({data,dates});
  }
  const householdTrend = async  () => {
    let trendReturn;
      trendReturn = await API.graphql(
        graphqlOperation(generateHouseholdTrendData, {generateHouseholdTrendDataInput:{}})
    );
      trendReturn = JSON.parse(trendReturn.data.generateHouseholdTrendData)
      const data = trendReturn.map(a => a.num_households);
      const dates = trendReturn.map(a => convertTime(a.Date));
      setHouseholdTrendData({data,dates});
  }

  const highChartsGraphs = () =>{
       return (
           <>
               <div>
                   <div >
                       <HighchartsReact
                           highcharts={Highcharts}
                           options={{
                               credits: {
                                   enabled: false,
                               },
                               title: {
                                   text: 'Household Trending'
                               },
                               xAxis: {
                                   categories: householdTrendData?.dates,
                               },
                               colors:['#2dd913'],
                               series: [{
                                   type: 'line',
                                   data: householdTrendData?.data,
                                   name: 'Households',
                               }]
                           }}
                       />
                   </div>
                   <div>
                       <HighchartsReact
                           highcharts={Highcharts}
                           options={{
                               credits: {
                                   enabled: false,
                               },
                               title: {
                                   text: 'Advisers Trending'
                               },
                               xAxis: {
                                   categories: adviserTrendData?.dates,
                               },
                               colors:['#0490ff'],
                               series: [{
                                   type: 'line',
                                   data: adviserTrendData?.data,
                                   name: 'Advisers',
                               }]
                           }}
                       />
                   </div>
                   <div>
                   <HighchartsReact
                       highcharts={Highcharts}
                       options={{
                           title: {
                               text: 'Practices Trending'
                           },
                           xAxis: {
                               categories: practiceTrendData?.dates,
                           },
                           colors:['#e201de'],
                           series: [{
                               type: 'line',
                               data: practiceTrendData?.data,
                               name: 'Practices',
                           }]
                       }}
                   />
               </div>
               </div>
           </>
       )
  }

  return (
      <>
          <div style={{display:'grid', gridTemplateColumns:'1fr', gap:'20px'}}>
              {highChartsGraphs()}
          </div>
      </>
  );
}
