import {Auth} from "@aws-amplify/auth";

export const ME_URL = "api/me";

export async function login(email, password) {
  return await Auth.signIn(email, password);
}

export async function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  let currentUser = await Auth.currentAuthenticatedUser();
  return currentUser;
}
